import React from "react"
import './contato.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';


function Contato() {

    return (

        <section id='contato' className="contatoContainer">
            <div className="row" style={{ margin: '0px' }}>
                {/* Primeira coluna */}
                <div className="d-flex justify-content-center">
                    {/* Conteúdo do WhatsApp */}
                    <div className="d-flex">
                        {/* Ícone do WhatsApp */}
                        <p className="whatsappIcon" >
                            <FontAwesomeIcon style={{ color: '#FFFFFF' }} icon={faWhatsapp}></FontAwesomeIcon>
                        </p>
                        {/* Texto do WhatsApp */}
                        <div style={{ color: '#FFFFFF' }}>
                            <div style={{ marginLeft: '0.5rem' }}>
                                <p className="whatsappTitulo">Whatsapp</p>
                                <p className="contatoText">Faça já seu pedido, é fácil e rápido</p>
                                <p className="whatsappNumero">(16) 9 9183-8452</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>



    )
}

export default Contato