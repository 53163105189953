import React from "react";
import './whatsappIcon.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function WhatsappIcon() {
    return (

  <div className="whatsappCircle">
    <a href="https://api.whatsapp.com/send?phone=+5516991838452&text=Ol%C3%A1,%20gostaria%20de%20fazer%20um%20pedido!"><FontAwesomeIcon className="whatsappIcon" icon={faWhatsapp} style={{margin: '0px'}} /></a>
  </div>
    )
}
export default WhatsappIcon;