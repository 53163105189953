import React from "react"
import './footer.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

function Footer() {

    return (

        <section className="">
            <div className="justify-content-center">
                <div className="footerContainer pt-5">
                    <p className="footerText1">Copyright © 2024 - Giuliana Baccarin Brigadeiros - Todos os direitos reservados.</p>
                    <span className="footerIcon"><a href="https://instagram.com/giulianabaccarinbrigadeiros">Siga-nos no Instagram <FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon></a></span>
                </div>
            </div>
        </section >



    )
}

export default Footer