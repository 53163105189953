import React, { useState, useEffect } from "react";
import './nav.css';
import 'bootstrap/dist/js/bootstrap.bundle'; // Importa o Bootstrap JavaScript
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
const logo = require('../../assets/logo.png');

function Nav() {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 1) { // ajuste o valor conforme necessário
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleNavLinkClick = (event: any) => {
        event.preventDefault();
        const targetId = event.currentTarget.getAttribute('href').substring(1); // Remove o '#' do href
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            const yOffset = -130; // Ajuste a quantidade de deslocamento conforme necessário
            const y = targetElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };

    return (
        <nav className={`navbar navbar-expand-xl fixed-top  ${scrolled ? 'scrolled' : ''}`}>
            <a className="navbar-brand brandSize" href="/">
                <img src={logo} alt="Logo Giuliana Baccarin" />
            </a>
            <button className="buttonNavbar navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" ></span>
            </button>
            <div className="collapse navbar-collapse teste" id="navbarNavAltMarkup">
                <div className="navbar-nav" style={{display:'flex', float:'right'}}>
                    <ul className='align-items-center ulEstilizado' style={{ marginBottom: '0px', paddingLeft: '0px' }}>
                        <li className="liEstilizado">
                            <a className="nav-link" href="/">HOME</a>
                        </li>
                        <li className="liEstilizado">
                            <a className="nav-link" href="#quemsomos" onClick={handleNavLinkClick}>QUEM SOMOS</a>
                        </li>
                        <li className="liEstilizado">
                            <a className="nav-link" href="#cardapio" onClick={handleNavLinkClick}>CARDÁPIO</a>
                        </li>
                        <li className="liEstilizado">
                            <a className="nav-link" href="#contato" onClick={handleNavLinkClick}>CONTATO</a>
                        </li>
                        <li className="ancora">
                            <a className="nav-link ancoraColor" href="https://api.whatsapp.com/send?phone=+5516991838452&text=Ol%C3%A1,%20gostaria%20de%20fazer%20um%20pedido!">WHATSAPP</a>
                        </li>
                        <li className='liEstilizado social-links'>
                            <a className="nav-link" href="https://instagram.com/giulianabaccarinbrigadeiros" target=""><FontAwesomeIcon style={{fontSize:'250%'}} icon={faInstagram} className="instagram-icon" /></a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav >
    );
}

export default Nav;
