import React, { useEffect, useState } from "react"
import './telaApresentacao.css';

const imagens = [require('../../assets/banner01.jpg'), require('../../assets/banner02.jpg'), require('../../assets/banner03.jpg')];
const titulos = ['O MAIS PEDIDO', 'PRESENTES INESQUECÍVEIS', 'VOLTA AO MUNDO']
const textos = ['Adoce a vida de quem você ama! Presenteie com nossos brigadeiros!',
  'Não é atoa que o nosso brigadeiro ao leite virou o preferido de vocês também. Vem saborear com a gente o brigadeiro mais gostoso do Brasil.',
  'Vem com a gente! Todo mês um sabor que te leva para um cantinho diferente do mundo.']; // Sua matriz de textos correspondentes

const TelaApresentacao: React.FC = () => {
  const [indiceImagem, setIndiceImagem] = useState(0);

  useEffect(() => {
    const intervalo = setInterval(() => {
      setIndiceImagem((prevIndice) => (prevIndice + 1) % imagens.length);
    }, 5000); // Altere o intervalo conforme necessário (5 segundos neste exemplo)
    return () => clearInterval(intervalo);
  }, []);

  return (
    <div className="image-gallery">
      <div className="background-container">
        {imagens.map((imagem, index) => (
          <div
            key={index}
            className={`background-image ${index === indiceImagem ? 'fade-in' : 'fade-out'}`}
            style={{ backgroundImage: `url(${imagem})` }}
          >
            {index === indiceImagem && (
              <div className="row row-flex textoPersonalizado" style={{color: '#43160b' }}>
                <div className="col-0 col-sm-1 col-md-2 col-lg-2 col-xl-2"></div>
                <div className="col-8 col-sm-5 col-md-5 col-lg-5 col-xl-4">
                  <div className="container-fluid">
                    <h1 style={{ fontSize: 'clamp(1rem, 2vw, 2.5rem)', fontWeight: "bolder" }}> {titulos[index]}</h1>
                    <span style={{ fontSize: 'clamp(0.95rem,1.2vw, 1.5rem)' }} dangerouslySetInnerHTML={{ __html: textos[index] }}></span>
                  </div>
                </div>
                <div className="col-4 col-sm-6 col-md-5 col-lg-5 col-xl-6"></div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TelaApresentacao;