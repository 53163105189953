import React from "react";
import './cardapio.css'
import CardapioCarrosselItem from '../CardapioCarrosselItem'
const aperol = require('../../assets/Aperol.jpg'); // Usar a importação padrão do JavaScript
const amarula = require('../../assets/Amarula.jpg'); // Usar a importação padrão do JavaScript
const limaoSiciliano = require('../../assets/Limao-Siciliano.jpg'); // Usar a importação padrão do JavaScript
const pistache = require('../../assets/pistache.jpg'); // Usar a importação padrão do JavaScript
const candy = require('../../assets/candy.jpg'); // Usar a importação padrão do JavaScript
const laranja = require('../../assets/Laranja.jpg'); // Usar a importação padrão do JavaScript
const jackDaniels = require('../../assets/Jack-1.jpg'); // Usar a importação padrão do JavaScript
const chocolateBranco = require('../../assets/branco.jpg'); // Usar a importação padrão do JavaScript
const meioAmargo = require('../../assets/Meio-amargo.jpg'); // Usar a importação padrão do JavaScript
const brulee2 = require('../../assets/brulee2.png'); // Usar a importação padrão do JavaScript
const aoLeite = require('../../assets/Ao-leite.jpg'); // Usar a importação padrão do JavaScript

function Cardapio() {
    return (
        <section id='cardapio'>
            <div className="cContainer mt-2">
                <h1 className="cText">C A R D Á P I O </h1>
            </div>

            <div className="cContainer mt-2">
                <h1 className="cText">Conheça Nossos Sabores</h1>
            </div>
            <div className="">
                <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="3" aria-label="Slide 4"></button>
                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="4" aria-label="Slide 5"></button>
                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="5" aria-label="Slide 6"></button>
                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="6" aria-label="Slide 7"></button>
                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="7" aria-label="Slide 8"></button>
                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="8" aria-label="Slide 9"></button>
                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="9" aria-label="Slide 10"></button>
                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="10" aria-label="Slide 11"></button>
                    </div>
                    <div className="carousel-inner">
                    <CardapioCarrosselItem
                            src={meioAmargo}
                            titulo="Meio amargo"
                            descricao="Para amantes de chocolates meio amargo nosso brigadeiro é a combinação perfeita."
                            ativo={true}
                            altura ="200px"
                        />
                        <CardapioCarrosselItem
                            src={brulee2}
                            titulo="Creme Brulée"
                            descricao="Com fava de baunilha, casquinha crocante e uma cremosidade única."
                            ativo={false}
                            altura ="200px"
                        />
                        <CardapioCarrosselItem
                            src={limaoSiciliano}
                            titulo="Limão siciliano"
                            descricao="Um sutil toque de limão siciliano em um brigadeiro de chocolate branco. Surpreenda-se."
                            ativo={false}
                            altura ="200px"
                        />
                        <CardapioCarrosselItem
                            src={pistache}
                            titulo="Pistache"
                            descricao="O puro sabor do pistache envolto em pralineée da própria castanha."
                            ativo={false}
                            altura ="200px"
                        />
                        <CardapioCarrosselItem
                            src={candy}
                            titulo="Candy"
                            descricao="Nosso brigadeiro mais divertido! Diversos tipos e cores para alegrar a criançada."
                            ativo={false}
                            altura ="200px"
                        />
                        <CardapioCarrosselItem
                            src={laranja}
                            titulo="Laranja"
                            descricao="A potência do chocolate meio amargo com a suavidade da laranja."
                            ativo={false}
                            altura ="200px"
                        />
                        <CardapioCarrosselItem
                            src={chocolateBranco}
                            titulo="Chocolate branco"
                            descricao="Toda a delicadeza, cremosidade e textura perfeita para apreciadores de chocolate branco."
                            ativo={false}
                            altura ="200px"
                        />
                        <CardapioCarrosselItem
                            src={aoLeite}
                            titulo="Ao leite"
                            descricao="O incomparável brigadeiro ao leite, sabor tradicional com gostinho de infância e uma cremosidade única."
                            ativo={false}
                            altura ="200px"
                        />
                        <CardapioCarrosselItem
                            src={aperol}
                            titulo="Aperol Sprits"
                            descricao="A reprodução de um clássico coquetel italiano com sabor cítrico da laranja."
                            ativo={false}
                            altura ="250px"
                        />
                        <CardapioCarrosselItem
                            src={amarula}
                            titulo="Amarula"
                            descricao="O sabor exótico do licor mais apreciado do mundo com brigadeiro ao leite, meio amargo ou brulée."
                            ativo={false}
                            altura ="250px"
                        />
                        <CardapioCarrosselItem
                            src={jackDaniels}
                            titulo="Jack Daniel’s"
                            descricao="O match perfeito! Whisky Jack Daniel’s combinado com chocolate meio amargo."
                            ativo={false}
                            altura ="250px"
                        />

                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div >
                </div >
                <div className="cContainer">
                    <h3 className="badge rounded-pill badge-color">Consulte aqui o cardápio completo</h3>
                </div>
            </div>

        </section>

    )
}

export default Cardapio