import React from "react";

interface CardapioCarrosselItemProps {
    src: string;
    titulo: string;
    descricao: string;
    ativo: boolean;
    altura: string;
}

const CardapioCarrosselItem: React.FC<CardapioCarrosselItemProps> = ({ src, titulo, descricao, ativo, altura }) => {
    return (
        <div className={`carousel-item ${ativo ? 'active' : ''}`}>
            <div className="d-flex justify-content-center">
                <img style={{ height: altura }} className='d-block mx-auto ' src={src} alt={titulo} />
            </div>
            <div className="text-center espacoAbaixo">
                <h5 className="cTitulo">{titulo}</h5>
                <p className="cTexto">{descricao}</p>
            </div>
        </div>
    )
}

export default CardapioCarrosselItem;