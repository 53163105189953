import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Note que agora estamos importando Routes
import './index.css';
import App from './App';
import PoliticaDePrivacidade from './components/PoliticaDePrivacidade'; // supondo que este seja o componente da política de privacidade


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
      <Route path="/" Component={App} />
      <Route path="/politica-de-privacidade" Component={PoliticaDePrivacidade} />
      </Routes>
    </Router>
  </React.StrictMode>


);

