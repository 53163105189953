import { useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from './components/Nav'
import WhatsappIcon from './components/WhatsappIcon'
import TelaApresentacao from './components/TelaApresentacao';
import BannerBrulee from './components/BannerBrulee';
import Quemsomos from './components/Quemsomos';
import Cardapio from './components/Cardapio';
import Contato from './components/Contato'
import Footer from './components/Footer'
// import CookieConsent from "react-cookie-consent";
// import { Link } from 'react-router-dom';

function App() {
  useEffect(() => {
    document.title = "Giuliana Baccarin";
  }, []);
  return (
    <div className='container-fluid' style={{ padding: '0px' }}>
      <Nav></Nav>
      <TelaApresentacao></TelaApresentacao>
      <BannerBrulee></BannerBrulee>
      <Quemsomos></Quemsomos>
      <Cardapio></Cardapio>
      <Contato></Contato>
      <Footer></Footer>
      {/* <CookieConsent
        location="bottom"
        zIndex="9999"
        buttonText="Aceitar"
        style={{ background: "#FFFFFF", color: '#43160B', textAlign: 'justify' }}
        buttonStyle={{ color: "#FFFFFF", fontSize: "16px", backgroundColor: '#43160B', fontWeight: 'bolder' }}
        enableDeclineButton
        declineButtonText="Recusar"
        declineButtonStyle={{ color: "#FFFFFF", fontSize: "16px", backgroundColor: '#43160B', fontWeight: 'bolder' }}
        expires={1}
      >
        Nós usamos cookies e outras tecnologias semelhantes para melhorar a sua experiência em nossos serviços. Para mais detalhes visite nossa página de
        <Link to="/politica-de-privacidade" style={{ color: '#43160B', fontWeight: 'bolder' }}> Política de Privacidade
        </Link>
      </CookieConsent> */}
      <WhatsappIcon></WhatsappIcon>
    </div>
  );
}

export default App;