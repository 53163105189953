import React from "react";
import './bannerBrulee.css'
const brulee = require('../../assets/brulee.png');

function BannerBrulee() {
    return (
        <section id='bannerBrulee'>
            <div className="row row-flex faixaMarron justify-content-center" style={{ margin: '0px' }}>
                <div className="col-lg-6 d-flex justify-content-center ">
                    <img className="imagemBrulee" src={brulee} alt="Imagem de Brigadeiro de Creme Brulee" />
                </div>
                <div className="col-lg-6 d-flex justify-content-center ">
                    <span className='fraseBanner'>Nossos brigadeiros e doces são produzidos artesanalmente, com chocolates nobres, ingredientes de primeira linha e seguindo um rigoroso padrão de qualidade para levar a você uma experiência inesquecível.</span>
                </div>
            </div>
        </section>
    )
}
export default BannerBrulee