import React from "react";
import './quemsomos.css'
const sign = require('../../assets/sign-giuliana.png'); // Usar a importação padrão do JavaScript
const quemSomos = require('../../assets/quem-somos.png'); // Usar a importação padrão do JavaScript

function Quemsomos() {
    return (<section id='quemsomos'>
        <div className="row row-flex" style={{ margin: '6% 0px' }}>
            <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                <div className="qsTitulo">Brigadeiros e Doces Giuliana Baccarin</div>
                <div className="qsTexto">
                    Em 2014, na cidade de São Carlos, movida pela paixão por brigadeiros e encorajada por sua irmã Giuliana Baccarin, que cursava Engenharia Ambiental na cidade de Uberlândia, Giovanna criou os Brigadeiros da Gi. Na época, o que era pra ser apenas uma fonte de renda extra, os Brigadeiros da Gi encantaram muitas pessoas pelo sabor incomparável, capricho e amor colocado em cada mínimo detalhe.
                    <br />
                    <br />
                    Giovanna teve que interromper os trabalhos por motivos particulares, mas os clientes não paravam de pedir para que ela continuasse. Foi então que Giuliana, que já auxiliava sua irmã, assumiu os Brigadeiros da Gi.
                    <br />
                    <br />
                    Atualmente em Piracicaba, SP, o negócio cresceu e a marca se tornou Giuliana Baccarin Brigadeiros e Doces. Sabores que remetem a experiências únicas, momentos e lembranças especiais, essa é a nossa Missão.
                </div>
                <div style={{ marginTop: '3%', textAlign: 'center' }}>
                    <img className="imagemSign" src={sign} alt="Assinatura Giuliana Baccarin"></img>
                </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-center align-items-center" style={{marginTop: '8%'}}><div >
                    <img className="imagemCaixa" src={quemSomos} alt="Imagem de uma caixa de brigadeiros"></img>
                </div>
            </div>
        </div>
    </section>
    )
}
export default Quemsomos;